.rdp {
    --rdp-cell-size: 2.2rem;
    --rdp-accent-color: var(--bs-gray-500);
    --rdp-background-color: #e7edff;
    /* Switch to dark colors for dark themes */
    --rdp-accent-color-dark: #3003e1;
    --rdp-background-color-dark: #180270;
    /* Outline border for focused elements */
    --rdp-outline: 2px solid var(--rdp-accent-color);
    /* Outline border for focused and selected elements */
    --rdp-outline-selected: 2px solid rgba(0, 0, 0, 0.75);

    margin: 0;

    &-caption {
        display: flex;
        align-items: center;
    }

    &-caption_label {
        font-size: 0.875rem;
        font-weight: 500;
        color: #373f50;
    }
}
