.product-img {
    img {
        object-fit: contain;
        object-position: center;
        // TODO: react-medium-image-zoom package zoom functionality breaks the img element height limits
        // set by parent and can cause for some images to take near limitless height. E.g. product TTS00001081R.
        // Setting max height limits that behaviour however it's not the best solution. Needs review.
        max-height: 6rem;
    }

    &-sm {
        height: 2rem;
        width: 2rem;
    }

    &-md {
        height: 3rem;
        width: 3rem;
    }

    &-lg {
        height: 5rem;
        width: 5rem;
    }

    &-auto {
        height: 100%;
        width: 100%;

        img {
            height: auto;
            width: auto;
            max-height: 100%;
            width: 100%;
        }
    }

    &-cover {
        img {
            object-fit: cover;
        }
    }
}
