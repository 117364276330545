.accordion-button:not(.collapsed):not(.clear .accordion-button) {
    color: #fe696a;
    background-color: #f3f5f9;
}

.accordion-header {
    &.final-level {
        .accordion-button::after {
            display: none;
        }
    }
}

.accordion {
    &.accordion-sm {
        .accordion-button {
            padding: 0.25rem 0.75rem;
        }

        .accordion-body {
            padding: 0.75rem 0.75rem;
        }
    }
}

html,
body,
#root {
    min-height: 100vh;
    height: auto;
    display: flex;
    flex-direction: column;
    font-size: 16px;

    @media screen and (max-width: 320px) {
        font-size: 14px;
    }
}

#root,
body {
    max-width: 100%;
    width: 100%;
}

.container {
    @media screen and (min-width: 1280px) {
        max-width: 96%;
    }

    @media screen and (min-width: 1921px) {
        max-width: 1900px;
    }
}

header {
    position: sticky;
    z-index: 1020;
    top: 0;
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 500ms;

    @media screen and (min-width: 992px) {
        position: fixed;
        left: 0;
        right: 0;
    }

    .topbar {
        padding: 0;
        height: 2.5rem;
    }

    &.disclaimer {
        .topbar {
            height: 4rem;
        }
    }

    .navbar-collapse {
        margin-top: 0;
        opacity: 1;
        transition: cubic-bezier(0.4, 0, 0.2, 1) 300ms;
    }

    @media screen and (max-width: 992px) {
        &.scroll-down:not(.menu-open):not(.keep-visible) {
            transform: translateY(-6.875rem);

            &.disclaimer {
                transform: translateY(-8.375rem);
            }
        }

        .navbar-header {
            height: 2.875rem;
        }

        /* Special override to keep react-bootstrap transitions working. */
        .navbar > .container.collapse:not(.show) {
            display: none;
        }
    }

    .navbar-tool.dropdown::before {
        width: 100%;
    }

    @media screen and (min-width: 992px) {
        &.scroll-down:not(.menu-open):not(.keep-visible) {
            transform: translateY(-2.5rem);

            &.disclaimer {
                transform: translateY(-4rem);
            }

            .navbar-collapse {
                margin-top: -3rem;
                z-index: -1;
                opacity: 0;
            }
        }
    }
}

main {
    @media screen and (min-width: 992px) {
        margin-top: 9.75rem;
    }
}

.navbar-tool {
    cursor: pointer;

    @media screen and (max-width: 456px) {
        position: unset;

        .dropdown-menu {
            left: 1rem !important;
            right: 1rem !important;
        }
    }

    &-text {
        padding-right: 1rem;

        &::after {
            position: absolute;
            top: 50%;
            right: 0;
        }
    }
}

/* colours */
.bg-green {
    background-color: #00bf6e !important;
}

.text-danger {
    color: #fe696a;
}

.bg-yellow {
    background: #ffcd36;

    &.badge-shadow {
        box-shadow: 0 0.5rem 1.125rem -0.275rem rgba(254, 214, 105, 0.9);
    }
}

.bg-blue {
    background: #5096ff;

    &.badge-shadow {
        box-shadow: 0 0.5rem 1.125rem -0.275rem rgba(0, 168, 255, 0.9);
    }
}

.carousel-inner {
    height: 100%;
    overflow: visible;
}

.page-item {
    @media screen and (max-width: 456px) {
        margin: 0.05rem;
    }
}

.badge {
    &.selected-product {
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: relative;
        font-size: 0.85em;
    }
}

.modal-body {
    .product-card {
        max-width: 100%;
    }
}

.product-card {
    flex: 1 1 auto;
    max-width: 100%;
    min-width: 21rem;
    transition: 0s all;

    @media screen and (min-width: 651px) {
        max-width: 50%;
    }

    @media screen and (min-width: 1440px) {
        max-width: 33.333%;
    }

    .product-img-auto {
        max-height: 6rem;
    }

    &:hover {
        padding-bottom: 0;
        transition-delay: 0.5s;

        .card-body {
            transition-delay: 0.5s;
        }
    }

    &.closed {
        .card-body-hidden {
            display: none;
        }
    }

    &-transparent:not(:hover) {
        .card-body {
            background: transparent;
        }
        background: transparent;
    }

    .card-body {
        max-height: 26rem;
        display: flex;
        padding: 0.75rem;
    }
}

.dropdown-toggle {
    cursor: pointer;
}

[data-rmiz] {
    margin: auto 0;
}

.widget {
    .final-level {
        .accordion-button-icon {
            display: none;
        }
    }

    .selected .accordion-button {
        color: #fe696a;
    }

    .accordion-button {
        color: #4b566b;

        &-icon {
            &::after {
                background-repeat: no-repeat;
                background-size: 1rem;
                transition: transform 0.2s ease-in-out;
                border-radius: 50%;
                background-position: center;
                font-family: "cartzilla-icons";
                font-size: 0.5rem;
                font-weight: bold;
                content: "";
                text-align: center;
                line-height: 1.375rem;
                width: 1.375rem;
                height: 1.375rem;
                padding-top: 0.0625rem;
                background-image: none;
                display: block;
                background-color: #f3f5f9;
            }

            &:hover::after {
                background-color: rgba(254, 105, 106, 0.1);
            }
        }

        &.collapsed {
            &-icon::after {
                background-color: #f3f5f9;
            }
        }

        &:not(.collapsed) &-icon::after {
            transform: rotate(-180deg);
        }

        &::after {
            display: none;
        }
    }
}

.hide-scrollbar {
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.gradient-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 1rem;
    height: 100%;
    background: linear-gradient(to left, rgba(255, 255, 255, 0) 0%, white 100%);
    z-index: 10;

    &.next {
        right: 0;
        left: unset;
        background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, white 100%);
    }

    @media screen and (min-width: 768px) {
        display: none;
    }
}

.letter-pagination {
    padding: 1rem 1rem;
    margin-left: calc(var(--bs-gutter-x) * 0.5 * -1);
    margin-right: calc(var(--bs-gutter-x) * 0.5 * -1);
}

.form-check-input {
    cursor: pointer;
}

/* react-medium-image-zoom overrides */

[data-rmiz],
[data-rmiz-content] {
    height: 100%;
}

/* utilities */
.overflow-scroll-y {
    overflow-y: scroll;
}

.overflow-auto {
    &-x {
        overflow-x: auto;
    }
    &-y {
        overflow-y: auto;
    }
}

.overflow-hidden {
    &-x {
        overflow-x: hidden;
    }
    &-y {
        overflow-y: hidden;
    }
}

.w-0 {
    width: 0;
    overflow: hidden;
}

.w-md-50 {
    @media screen and (min-width: 768px) {
        width: 50%;
    }
}

.cursor-pointer {
    cursor: pointer;
}

/* react-window */

.client-row {
    transition: all 200ms ease-in-out;

    .header {
        height: 64px;
    }

    .location {
        height: 48px;
    }
}

.icon {
    height: 1.25rem;
    width: 1.25rem;
}

/** z-index **/
.z-1 {
    z-index: 1;
}

/** keep iOS from auto-zooming on input focus **/
.search-bar {
    input {
        font-size: max(1rem, 16px);
    }
}

/** Animations **/
.animate-spin {
    animation: 1s linear infinite spinner-border;
}

/** Remove number input arrows **/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

input:invalid {
    border: 1px solid #fe696a;
}
