.toasts-wrapper {
    position: fixed !important;
    position: relative;
    right: 0;
    bottom: 0;
    z-index: 1000;

    @media screen and (max-width: 992px) {
        bottom: 3.75rem;
    }

    @media screen and (max-width: 456px) {
        left: 0;

        .toast-container {
            margin: 0 auto;
        }
    }
}

.toast {
    &-header {
        :last-child {
            margin-left: auto !important;
            margin-right: 0 !important;
        }

        .btn-close {
            filter: invert(1) grayscale(100%) brightness(200%);
        }
    }
}
