.img-placeholder {
    &-sm {
        height: 2rem;
        width: 2rem;
    }

    &-md {
        height: 3rem;
        width: 3rem;
    }

    &-lg {
        height: 5rem;
        width: 5rem;
    }

    &-auto {
        height: 0;
        width: 100%;
        padding-bottom: 100%;
        position: relative;

        > * {
            top: 0;
            left: 0;
            position: absolute;
            width: 100%;
            height: 100%;
        }
    }
}
